var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"evenement-list"},[_c('Toolbar',{attrs:{"handle-add":_vm.addHandler}}),_c('v-container',{attrs:{"grid-list-xl":"","fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"sm12":""}},[_c('h1',[_vm._v("Dates de Ludinam")])]),_c('v-flex',{attrs:{"lg12":""}},[_c('DataFilter',{attrs:{"handle-filter":_vm.onSendFilter,"handle-reset":_vm.resetFilter}},[_c('EvenementFilterForm',{ref:"filterForm",attrs:{"slot":"filter","values":_vm.filters},slot:"filter"})],1),_c('br'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.options.itemsPerPage,"loading":_vm.isLoading,"loading-text":_vm.$t('Loading...'),"options":_vm.options,"server-items-length":_vm.totalItems,"item-key":"@id","show-select":"","footer-props":{
            'items-per-page-options': [15, 30, 50, 100],
          }},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:options":[function($event){_vm.options=$event},_vm.onUpdateOptions]},scopedSlots:_vm._u([{key:"item.dateDebut",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(item['dateDebut'], 'long'))+" ")]}},{key:"item.dateFin",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(item['dateFin'], 'long'))+" ")]}},{key:"item.sites",fn:function(ref){
          var item = ref.item;
return [_c('ul',_vm._l((item['sites']),function(_item){return _c('li',{key:_item.id},[_vm._v(" "+_vm._s(_item.id)+" ")])}),0)]}},{key:"item.animations",fn:function(ref){
          var item = ref.item;
return _vm._l((item['animations']),function(_item,index){return _c('div',{key:_item.id},[(index == _vm.items.length - 1)?_c('div',[_vm._v(" "+_vm._s(index)+" ")]):_vm._e()])})}},{key:"item.action",fn:function(props){return _c('ActionCell',{attrs:{"handle-show":function () { return _vm.showHandler(props.item); },"handle-edit":function () { return _vm.editHandler(props.item); },"handle-delete":function () { return _vm.deleteHandler(props.item); }}})}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }